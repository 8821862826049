.chat-icon {
    rotate: 5deg;
}

.chat-icon:hover {
    animation: tilt-shaking 1s infinite linear;
}

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(15deg);
    }
    50% {
        transform: rotate(0eg);
    }
    75% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
