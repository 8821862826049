.audio-box-path {
    border: 3px solid;
    border-image: linear-gradient(45deg, gold, deeppink) 1;
    clip-path: inset(0px round 3px);
    animation: huerotate 6s infinite linear;
    filter: hue-rotate(360deg);
    border-radius: 10px;
}

@keyframes huerotate {
    0% {
        filter: hue-rotate(0deg);
    }
    100% {
        filter: hue-rotate(360deg);
    }
}