
.maia-markdown-editor {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.maia-markdown-editor-content {
    margin-top: 8px;
    background-color: white;
    border-radius: 6px;
    max-height: 160px;
    overflow-y: scroll;
}