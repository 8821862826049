progress {
    border-radius: 0;
    width: 100%;
    height: 4px;
    display: flex;
}
progress::-webkit-progress-bar {
    background-color: #ffe0f9;
    border-radius: 0;
}
progress::-webkit-progress-value {
    background-color: #fdb3ed;
    border-radius: 0;
}
progress::-moz-progress-bar {
    /* style rules */
}